import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.NAME" />,
        showInDashboard: true,
        selector: 'NAME',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.IDENTIFIER" />,
        showInDashboard: true,
        selector: 'IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
        showInDashboard: true,
        selector: 'ROOT_OBJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
        showInDashboard: true,
        selector: 'PARENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PARENT_IDENTIFIER',
        isCard: true
    },



    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
        showInDashboard: true,
        selector: 'REGION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
        showInDashboard: true,
        selector: 'CITY',
        isCard: true
    },
]
