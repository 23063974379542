
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'

export const getObjectStates = (href) => {
  if (
    href.includes('installations') ||
    href.includes('passportization') || 
    href.includes('maps') || 
    href.includes('analytics-alarms') || 
    href.includes('report-manager') ||
    href.includes('period-of-exploitation')
  ) {
    return []
  }
  return [INSTALLED, UNINSTALLED]
}