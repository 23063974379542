import { handleActions } from 'redux-actions'
import REQUEST_STATUSES from '@/constants/requests'
import {
  GET_CONTROL_DATA,
  GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS,
  GET_CREATE_OBJECTS_GROUP_SEARCH_TREE,
  GET_CREATE_OBJECTS_GROUP_TREE,
  GET_OBJECTS_GROUP,
  GET_OBJECTS_GROUPS_MAP_OBJECTS,
  GET_OBJECTS_GROUPS_TREE,
  GET_PASSPORTIZATION_OPTIONS,
  GET_PASSPORTIZATION_TABLE,
  GET_PASSPORTIZATION_TABLE_FILE,
  GET_SCHEDULE_DATA,
  GET_SCHEDULE_OPTIONS,
  GET_VERIFICATION_OPTIONS,
  PASSPORTIZATION_TABLE,
  SET_OBJECTS_GROUPS_TREE_NODE,
  SET_VERIFICATION_STATUS,
  VERIFICATION_ALL,
  SET_LOADING,
  SET_CONTROL_CUPBOARD_ORIGINAL_DICTIONARY,
  SET_SCHEDULE_ERROR_STATUS,
} from '@/store/actions/objectsGroups'
import generateDefaultTableReducer from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'

const initialState = {
  tree: [],
  treeStatus: REQUEST_STATUSES.NOT_REQUESTED,
  searchTree: [],
  createObjectsGroupTree: [],
  createObjectsGroupTreeStatus: REQUEST_STATUSES.NOT_REQUESTED,
  card: {},
  selectedNode: {},
  mapObjects: [],
  mapObjectsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  createObjectsGroupMapObjects: [],
  createObjectsGroupMapObjectsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  scheduleData: {},
  scheduleOptions: [],
  isSetScheduleErrorStatus: false,
  verificationOptions: [],
  verificationOptionsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  verificationStatus: REQUEST_STATUSES.NOT_REQUESTED,
  controlData: {},
  passportizationOptions: [],
  passportizationOptionsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  table: generateDefaultTableState([]),
  passportizationTableData: [],
  loadingTable: false,
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  tableFields: [],
  controlCupboardOriginalDictionary: []
}

export const reducer = handleActions(
 {
  [SET_LOADING]: (state, {payload}) => ({
    ...state,
    loadingTable: payload,
  }),
  [GET_OBJECTS_GROUPS_TREE.REQUEST]: (state) => ({
    ...state,
    tree: [],
    treeStatus: REQUEST_STATUSES.PENDING,
  }),
  [GET_OBJECTS_GROUPS_TREE.SUCCESS]: (state, { payload }) => ({
    ...state,
    tree: payload,
    treeStatus: REQUEST_STATUSES.IDLE
  }),
  [GET_OBJECTS_GROUPS_TREE.ERROR]: (state) => ({
    ...state,
    treeStatus: REQUEST_STATUSES.ERROR
  }),
  [GET_CREATE_OBJECTS_GROUP_TREE.REQUEST]: (state) => ({
    ...state,
    createObjectsGroupTree: [],
    createObjectsGroupTreeStatus: REQUEST_STATUSES.PENDING
  }),
  [GET_CREATE_OBJECTS_GROUP_TREE.SUCCESS]: (state, { payload }) => ({
    ...state,
    createObjectsGroupTree: payload,
    createObjectsGroupTreeStatus: REQUEST_STATUSES.IDLE
  }),
  [GET_CREATE_OBJECTS_GROUP_TREE.ERROR]: (state) => ({
    ...state,
    createObjectsGroupTreeStatus: REQUEST_STATUSES.ERROR
  }),
  [GET_CREATE_OBJECTS_GROUP_SEARCH_TREE.SUCCESS]: (state, { payload }) => ({
    ...state,
    searchTree: payload,
  }),
  [GET_OBJECTS_GROUP.REQUEST]: (state) => ({
    ...state,
    card: {}
  }),
  [GET_OBJECTS_GROUP.SUCCESS]: (state, { payload }) => ({
    ...state,
    card: payload.card,
    mapObjects: payload.displayElements
  }),
  [GET_OBJECTS_GROUP.ERROR]: (state) => ({
    ...state,
    card: {},
  }),
  [GET_OBJECTS_GROUPS_MAP_OBJECTS.REQUEST]: (state) => ({
    ...state,
    mapObjectsStatus: REQUEST_STATUSES.PENDING
  }),
  [GET_OBJECTS_GROUPS_MAP_OBJECTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    mapObjects: payload.displayElements,
    mapObjectsStatus: REQUEST_STATUSES.IDLE
  }),
  [GET_OBJECTS_GROUPS_MAP_OBJECTS.ERROR]: (state) => ({
    ...state,
    mapObjects: [],
    mapObjectsStatus: REQUEST_STATUSES.ERROR
  }),
  [GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.REQUEST]: (state) => ({
    ...state,
    createObjectsGroupMapObjectsStatus: REQUEST_STATUSES.PENDING
  }),
  [GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    createObjectsGroupMapObjects: payload.displayElements,
    createObjectsGroupMapObjectsStatus: REQUEST_STATUSES.IDLE
  }),
  [GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.ERROR]: (state) => ({
    ...state,
    createObjectsGroupMapObjects: [],
    createObjectsGroupMapObjectsStatus: REQUEST_STATUSES.ERROR
  }),
  [GET_SCHEDULE_OPTIONS.SUCCESS]: (state, { payload }) => ({
    ...state,
    scheduleOptions: payload,
  }),
  [GET_VERIFICATION_OPTIONS.REQUEST]: (state) => ({
    ...state,
    verificationOptionsStatus: REQUEST_STATUSES.PENDING,
  }),
  [GET_VERIFICATION_OPTIONS.SUCCESS]: (state, { payload }) => ({
    ...state,
    verificationOptions: payload,
    verificationOptionsStatus: REQUEST_STATUSES.IDLE,
  }),
  [SET_VERIFICATION_STATUS.REQUEST]: (state) => ({
    ...state,
    verificationStatus: REQUEST_STATUSES.PENDING,
  }),
  [SET_VERIFICATION_STATUS.SUCCESS]: (state) => ({
    ...state,
    verificationStatus: REQUEST_STATUSES.IDLE,
  }),
  [SET_VERIFICATION_STATUS.ERROR]: (state) => ({
    ...state,
    verificationStatus: REQUEST_STATUSES.ERROR,
  }),
  [VERIFICATION_ALL.SUCCESS]: (state) => ({
    ...state,
    verificationStatus: REQUEST_STATUSES.IDLE,
  }),
  [VERIFICATION_ALL.ERROR]: (state) => ({
    ...state,
    verificationStatus: REQUEST_STATUSES.ERROR,
  }),
  [GET_CONTROL_DATA.REQUEST]: (state) => ({
    ...state,
    controlData: {},
  }),
  [GET_CONTROL_DATA.SUCCESS]: (state, { payload }) => ({
    ...state,
    controlData: payload,
  }),
  [GET_CONTROL_DATA.ERROR]: (state) => ({
    ...state,
    controlData: {},
  }),
  [GET_SCHEDULE_DATA.REQUEST]: (state) => ({
    ...state,
    scheduleData: {},
  }),
  [GET_SCHEDULE_DATA.SUCCESS]: (state, { payload }) => ({
    ...state,
    scheduleData: payload,
  }),
  [GET_SCHEDULE_DATA.ERROR]: (state) => ({
    ...state,
    scheduleData: {},
  }),
  [GET_PASSPORTIZATION_OPTIONS.REQUEST]: (state) => ({
    ...state,
    passportizationOptions: [],
    passportizationOptionsStatus: REQUEST_STATUSES.PENDING,
    table: generateDefaultTableState(),
  }),
  [GET_PASSPORTIZATION_OPTIONS.SUCCESS]: (state, { payload }) => ({
    ...state,
    passportizationOptions: payload,
    passportizationOptionsStatus: REQUEST_STATUSES.IDLE
  }),
  [GET_PASSPORTIZATION_OPTIONS.ERROR]: (state, { payload }) => ({
    ...state,
    passportizationOptionsStatus: REQUEST_STATUSES.ERROR
  }),
  [GET_PASSPORTIZATION_TABLE.REQUEST]: (state,) => ({
    ...state,
  }),
  [GET_PASSPORTIZATION_TABLE.SUCCESS]: (state, { payload: {tableFields, data , totalElements, page, perPage} }) => ({
    ...state,
    passportizationTableData: data,
    tableFields: tableFields,
    table: generateDefaultTableState(tableFields, {}, '', totalElements, page, perPage),
  }),
  [GET_PASSPORTIZATION_TABLE.ERROR]: (state) => ({
    ...state,
    loadingTable: false
  }),
  [GET_PASSPORTIZATION_TABLE_FILE.REQUEST]: (state) => ({
    ...state,
    fileExportStatus: REQUEST_STATUSES.PENDING,
  }),
  [GET_PASSPORTIZATION_TABLE_FILE.SUCCESS]: (state) => ({
    ...state,
    fileExportStatus: REQUEST_STATUSES.IDLE,
  }),
  [GET_PASSPORTIZATION_TABLE_FILE.ERROR]: (state) => ({
    ...state,
    fileExportStatus: REQUEST_STATUSES.ERROR,
  }),
  [SET_OBJECTS_GROUPS_TREE_NODE]: (state, { payload }) => ({
    ...state,
    selectedNode: payload,
  }),
  [SET_CONTROL_CUPBOARD_ORIGINAL_DICTIONARY]: (state, { payload }) => ({
    ...state,
    controlCupboardOriginalDictionary: payload,
  }),
  [SET_SCHEDULE_ERROR_STATUS]: (state, { payload }) => ({
    ...state,
    isSetScheduleErrorStatus: payload,
  }),
  ...generateDefaultTableReducer(PASSPORTIZATION_TABLE),
 },
 initialState,
)

export default reducer