import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateSortedUsers } from '@/constants/apiRoutes'
import {
  getAdmins,
  getTenants,
  getAllUsers,
  getSelectedTenant,
} from '@/store/selectors/users'
import { getUserData } from '@/store/selectors/appSettings'
import { ADMIN, USER } from '@/constants/viewTree'
import {
  responseGetAllAdminTenants,
  errorGetAllAdminTenants,
} from '@/store/actions/users/administrator/getAllAdminTenants'
import { GET_TENANT_ADMINS } from '@/store/actions/users'
import getFullName from '@/helpers/fullName'
import {
  responseGetAllUsers,
} from '@/store/actions/users/user/getAllUsers'
import {
  responseGetAllTenants,
} from '@/store/actions/users/tenant/getAllTenants'
import {
  OK,
  ERROR,
} from '@/constants/objectStatuses'
import {
  ROLE_TENANT_ADMIN,
  ROLE_SUPER_ADMIN,
} from '@/constants/role'
import createNotifications from "@/helpers/notification";

function* getAllAdminTenantsSaga({ payload }) {
  const { id, role } = payload
  const currentUser = yield select(getUserData)
  const selectedTenant = yield select(getSelectedTenant)
  const tenantId = id || selectedTenant.id
  try {
    const response = yield request({
      url: generateSortedUsers({
        currentRole: currentUser.role,
        entityId: tenantId,
        params: {
          role,
        },
      }),
      method: 'get',
    })
    if (response.data) {
      const filteredAdmins = response.data.reduce((accumulator, element) => {
        if (element.role === ROLE_TENANT_ADMIN) {
          let status = element.enabled ? OK : null

          if (element.blocked) {
            status = ERROR
          }
          return [
            ...accumulator,
            {
              type: ADMIN,
              ...element,
              status,
              name: getFullName(element.lastName, element.firstName, element.middleName),
            },
          ]
        }
        return accumulator
      }, [])
      const admins = yield select(getAdmins)
      const tenants = yield select(getTenants)
      const updatedTenants = tenants.map((element) => {
        if (element.id !== tenantId) {
          return element
        }
        return {
          ...element,
          toggled: false,
          loading: false,
          children: [],
        }
      })
      const newAdmins = {
        ...admins,
        [tenantId]: filteredAdmins,
      }
      yield put(responseGetAllTenants(updatedTenants))
      yield put(responseGetAllAdminTenants(newAdmins))
      const users = yield select(getAllUsers)
      const filteredUsers = response.data.reduce((accumulator, element) => {
        if (element.role === 'ROLE_USER') {
          let status = element.enabled ? OK : null
          if (element.blocked) {
            status = ERROR
          }
          const newElement = {
            type: USER,
            ...element,
            status,
            name: getFullName(element.lastName, element.firstName, element.middleName),
          }
          const accumulatorGroup = accumulator[`${element.tenantId}-${element.group.id}`] || []
          accumulatorGroup.push(newElement)
          return {
            ...accumulator,
            [`${element.tenantId}-${element.group.id}`]: accumulatorGroup,
          }
        }
        return accumulator
      }, {})

      if (Object.keys(filteredUsers).length > 0) {
        if (currentUser.role === ROLE_SUPER_ADMIN || currentUser.role === ROLE_TENANT_ADMIN) {
          yield put(responseGetAllUsers({
            ...users,
            ...filteredUsers,
          }))
        }
      }
    } else {
      // error handler
    }
  } catch (error) {
    const tenants = yield select(getTenants)
    const updatedTenants = tenants.map((element) => {
      if (element.id !== tenantId) {
        return element
      }
      return {
        ...element,
        loading: false,
        toggled: false,
        children: [],
      }
    })
    yield put(errorGetAllAdminTenants(updatedTenants))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_TENANT_ADMINS.REQUEST, getAllAdminTenantsSaga)
}
