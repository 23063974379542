import {
  put, takeLatest, all, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { getObjectStates } from '@/helpers/getObjectStates'
import { successGetRootElements, errorGetRootElements, successGetTreeElements } from '@/store/actions/installation/getRootElemenet'
import { INSTALLATION_ELEMENT_CHILDREN, MAPS_ELEMENT_CHILDREN, generateGetZonesStatistic } from '@/constants/apiRoutes'
import { getChildren } from '@/helpers/getTreeChild'
import {
  CREATE_GEOZONE,
  DELETE_GEOZONE,
  EDIT_GEOZONE,
  GET_ROOT_ELEMENTS
} from '@/store/actions/installation'
import {CITY, OBJECT_ELEMENT, ROOT} from '@/constants/objectTypes'
import createNotifications from '@/helpers/notification'
import { getGlobalFilters, getStatusGlobalFilter } from '@/store/selectors/appSettings'
import {
  SET_GLOBAL_FILTER_VALUES,
  RESET_GLOBAL_FILTER_VALUES,
  setFalseStatusGlobalFilter,
} from '@/store/actions/appSettings'
import {
  successGetPassportizationData
} from '@/store/actions/passportization/getPassportizationData'
import { GET_SETTINGS } from '@/store/actions/user'
import isEqual from 'lodash/isEqual'
import { globalFiltersDefaultValues } from '@/constants/filters'
import { generateGetParentNode, generateGetParentNodeTelemetry } from '@/constants/apiRoutes'


function* getRootElementSaga({payload}) {
  yield put(successGetRootElements(null))
  const {href} = window.location
  const objectStates = getObjectStates(href)
  const url = href.includes('maps') ? MAPS_ELEMENT_CHILDREN : INSTALLATION_ELEMENT_CHILDREN
  const requestStatusGlobalFilter = yield select(getStatusGlobalFilter)
  const noNeedRequestTree = href.includes('users') || href.includes('search') || href.includes('analytics-alarms') || href.includes('schedule-manager') || href.includes('objects-groups')
  const withZones = href.includes('maps') || href.includes('passportization') || href.includes('installation')
  const ppr = href.includes('ppr')
  const urlTree =  href.includes('maps') ?  generateGetParentNodeTelemetry : generateGetParentNode 

  try {
    if (requestStatusGlobalFilter && !noNeedRequestTree) {
      const globalFilters = yield select(getGlobalFilters)
      const { asu , objectsTypes, integrations, processes, owners, exploitationOrganizations, ...statuses} = globalFilters
      const statusesTrue = Object.keys(statuses).filter((status) => statuses[status])
      const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
      const { data } = yield request({
        url: url({
          params: {
            integrationTypes: asu,
            installationTypes: objectsTypes,
            integrationIds: integrations,
            cityProcessTypes: processes,
            telemetryStatuses,
            objectStates,
            owners,
            exploitationOrganizations,
          }
        }),
        timeout: 5000,
        method: 'get',
      })
      let formattedRequestData 
        formattedRequestData = yield all(data.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map((element) => {
        return {
          ...element,
          type: ROOT,
          name: element.name,
          count: element.countChildElements,
          rootGeoZone: element.id,
          childrenCount: element.countChildElements,
          point: element.point,
          getChildren: getChildren({
            rootId: element.id,
            closestGeoZoneId: element.id,
            parentTreeId: element.id,
            asu,
            objectsTypes,
            integrations,
            telemetryStatuses,
            objectStates,
            processes,
            exploitationOrganizations,
            owners,
            url,
            ppr,
          }),
        }
      }))

      const response = yield request({
        url: urlTree({
          params: {
            parentId: data[0]?.id,
            integrationTypes: asu,
            installationTypes: objectsTypes,
            integrationIds: integrations,
            cityProcessTypes: processes,
            telemetryStatuses,
            objectStates,
            owners,
            exploitationOrganizations,
            pageSize: 99999,
            includeAll: true,
          }
        }),
        method: 'get',
      })
        const formattedtData = response.data.content
        let treeData = [{...data[0], type: ROOT}]
        treeData[0].children = formattedtData.filter(item => item.parentId === treeData[0].id)
    
        const getNodeChildren = ( id, data) => {
          let children = data.filter(item => item.parentId === id)
          const testData = data.filter(item => item.path.includes(id))
          if (children.length) {
            children.forEach(item => {
              item.children = getNodeChildren(item.id, testData)
              item.type = item.treeNodeType === OBJECT_ELEMENT ? item.installationType : item.treeNodeType
            })
          }
          return children
        }
        treeData[0].children.forEach(item => {
          item.children = getNodeChildren(item.id, formattedtData.filter(node => node.path.includes(item.id)))
        })
        yield put(successGetTreeElements(treeData))

      if (!data.length && !isEqual(globalFiltersDefaultValues, globalFilters)) {
        const toast = createNotifications()
        toast({
          title: 'Ошибка операции!',
          description: 'Не найдены объекты, соответствующие выбранным фильтрам.',
          type: 'error',
        })
        yield put(successGetRootElements([]))
        yield put(successGetPassportizationData({
          zones: [],
        }))
      }
      if (withZones) {
        let newZones = {}
        const { data: zones }  = yield request ({
          url: INSTALLATION_ELEMENT_CHILDREN({
            params: {
              parentId: data[0]?.id,
              integrationTypes: asu,
              installationTypes: objectsTypes,
              integrationIds: integrations,
              cityProcessTypes: processes,
              telemetryStatuses,
              exploitationOrganizations,
              owners
            }
          }),
          method: 'get',
        })

        zones.forEach((item) => {
          newZones[item.id] = {
            ...item,
            location: [item.point.latitude, item.point.longitude],
            type: CITY,
            zoneSelector: [item.id]
          }
        })
        if (href.includes('maps')) {
          const { data: zoneStatistic } = yield request( {
            url: generateGetZonesStatistic({
              params: {
                parentId: data[0]?.id,
                includeParent: true,
                integrationTypes: asu,
                installationTypes: objectsTypes,
                integrationIds: integrations,
                cityProcessTypes: processes,
                telemetryStatuses,
                exploitationOrganizations,
                owners
              }}),
            method: 'get'
          })
          zoneStatistic.forEach((zone) => {
            if (newZones[zone.id]) {
              newZones[zone.id] = {
                ...newZones[zone.id],
                statistic: {
                  NO_PROBLEM: zone.ok,
                  WARNING: zone.warning,
                  ERROR: zone.error,
                  UNDEFINED: zone.undefined
                }
              }
            }
          })
        }
        yield put(successGetPassportizationData({
          zones: newZones,
        }))
      }
      yield put(setFalseStatusGlobalFilter())
      yield put(successGetRootElements(formattedRequestData))
  } 
  } catch (error) {
    if (error.message.includes('timeout')) {
      yield put(errorGetRootElements())
      return
    }
    console.log('function*getRootElementSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные геозон.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_ROOT_ELEMENTS.REQUEST, getRootElementSaga)
  yield takeLatest(SET_GLOBAL_FILTER_VALUES, getRootElementSaga)
  yield takeLatest(RESET_GLOBAL_FILTER_VALUES, getRootElementSaga)
  yield takeLatest(GET_SETTINGS.SUCCESS, getRootElementSaga)
  yield takeLatest(DELETE_GEOZONE.SUCCESS, getRootElementSaga)
  yield takeLatest(CREATE_GEOZONE.SUCCESS, getRootElementSaga)
  yield takeLatest(EDIT_GEOZONE.SUCCESS, getRootElementSaga)
}
