import React, {
  useCallback,
  useEffect, useRef,
  useState
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import DutyTable from '@/components/blocks/DutyTable'
import moment from 'moment'
import useReduxTable from '@/hooks/useReduxTable'
import { PPR_TABLE } from '@/store/actions/Ppr'
import { getTableParameters } from '@/store/selectors/ppr'
import {
  fields,
  exportOptions,
  settingsOptions,
  settingsYear,
} from '@/constants/tablesConfig/Ppr'

import {
  Main,
  Content,
  Title,
  Container,
  ContentContainer,
  Header,
  IconContainer,
  IconWrapper,
  TypesWrapper,
  TypeContainer,
  TypeTitle,
  ToggleWrapper,
  ToggleContainer,
  ChildrenCount,
  ObjectsWrapper,
  ObjectContainer
} from './styles'

import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import HeaderIcons from '@/components/icons/header'
import viewTreeIcons from '@/constants/viewTree'
import Loader from '@/components/blocks/Loader'
import uniqBy from 'lodash/uniqBy'

const Ppr = ({
  setNode,
  selectedNode,
  requestPprTable,
  data,
  globalLoading,
  year,
  setYear,
  setStatus,
  status,
  dates,
  installationObject,
  installationObjectLoading,
  setDatesRange,
  requestPprFile,
  requestPprInstallationObject,
  setGoToPassportization
}) => {

  const tableProps = useReduxTable(PPR_TABLE, getTableParameters)
  const [title, setTitle] = useState('Для построения отчета выберите геозону и даты')
  const [showDetails, setShowDetails] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [installationTypes, setInstallationTypes] = useState([])
  const ref = useRef(null)

  useEffect(() => {
    setInstallationTypes(installationObject)
  }, [installationObject])

  useEffect(() => {
    if (!(dates.start || dates.end) && selectedNode.id) {
      setTitle('Для построения отчета выберите даты')
    }
    if ((dates.start || dates.end) && !selectedNode.id) {
      setTitle('Для построения отчета выберите геозону')
    }
  },[dates, selectedNode])

  const setDate = (value) => {
    setDatesRange(value)
  }

  const handleSetNode = useCallback((node, event) => {
    if (event === 'click') {
      setNode(node)
      if ((dates.start || dates.end)) {
        requestPprTable()
      }
    }
  },
  [setNode, requestPprTable, dates])

  const titlePpr = () => <Title>{title}</Title>

  const testData = data.map((item) => {
    
    const factDate = item.details.map((item) => {
      let plan = null
      let fact = null
      const now = moment()
      if (item.planDate) {
        plan = moment(item.planDate) 
      }
      if (item.factDate) {
        fact = moment(item.factDate) 
      }
      if (!fact && plan) {
        return {date: moment(item.factDate).format('DD.MM.YYYY'), expired: plan < now}
      }
      if (!plan && fact) {
        return {date: moment(item.factDate).format('DD.MM.YYYY'), expired: false}
      }
      return {date: moment(item.factDate).format('DD.MM.YYYY'), expired: plan < fact}
    })
    return ({
      ...item, 
      originalInstallationType: item.details.map((item) => item.originalInstallationType),
      planDate: item.details.map((item) => moment(item.planDate).format('DD.MM.YYYY')),
      factDate: factDate,
    })
  })

  const onRowSelect = useCallback((row) => {
    requestPprInstallationObject({
      parentId: row.parentId,
      registerNumber: row.registerNumber,
      details: row.details.map(item => {
        return {
          name: item.originalInstallationType,
          installationType: item.installationType,
        }
      }),
    })
    setShowDetails(true)
    setSelectedRow(row)
  }, [requestPprInstallationObject])

  const handleCloseDetails = useCallback(() => () => {
    setShowDetails(false)
  }, [])

  const returnName = useCallback((name) => {
    const limit = 70

    return (
      <>
        {((name || '').length > limit)
          ? <PortalTooltip
              title={name}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <Title
                   ref={wrapperRef}
                   onMouseEnter={onMouseEnterHandler}
                   onMouseLeave={onMouseLeaveHandler}
                >
                  {(name || '').substr(0, limit)}
                  ...
                </Title>
              )}
            />
          : <Title>
              {name}
            </Title>
          }
      </>
    )
  }, [])

  const handleTypeToggle = useCallback((type) => () => {
    const formattedTypes = installationTypes.map(item => {
      return {
        ...item,
        opened: item.name === type.name ? !item.opened : item.opened,
      }
    })
    setInstallationTypes(formattedTypes)
  }, [installationTypes, setInstallationTypes])

  const handleObjectClick = useCallback((item) => () => {
    setGoToPassportization(true)
    setNode(item)
  }, [setNode, setGoToPassportization])

  const uniqueInstallationTypes = uniqBy(installationTypes, 'installationType')

  return (
    <Main>
      <Content>
        <TreeSidebar
          title={<Lang id={"ppr.title"}/>}
          onSelect={handleSetNode}
          hidden={false}
          selectedNode={selectedNode}
          treeId={'PPR-tree'}
        />
        <DutyTable
          withDates
          {...tableProps}
          fields={fields}
          data={testData}
          exportOptions={exportOptions}
          settingsStatus={settingsOptions}
          dates={dates}
          year={year}
          status={status}
          titlePpr={titlePpr}
          isDataLoading={globalLoading}
          selectedView={'table'}
          onFileSelect={requestPprFile}
          onUpdateData={requestPprTable}
          settingsYear={settingsYear}
          setYear={setYear}
          setStatus={setStatus}
          getTableData={setDate}
          selectedNodeId={!!selectedNode.id}
          rowSelector={onRowSelect}
          selectedRow={selectedRow}
        />
        {showDetails &&
          <Container>
            <Header>
              {returnName(selectedRow.installation)}
              <PortalTooltip
                title={<Lang id="buttons.close" />}
                handleIconClick={() => {}}
                renderChildren={(
                  wrapperRef,
                  onMouseEnterHandler,
                  onMouseLeaveHandler,
                  onClickHandler
                ) => (
                  <IconWrapper
                    onClick={onClickHandler}
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                  >
                    <IconContainer onClick={handleCloseDetails()}>
                      <HeaderIcons.MenuCloseIcon />
                    </IconContainer>
                  </IconWrapper>
                )}
              />
            </Header>
            <ContentContainer>
              { installationObjectLoading
                ? <Loader center />
                : uniqueInstallationTypes.map(type => {
                  return (
                    <>
                      <TypesWrapper key={type.name}>
                        <TypeContainer>
                          <TypeTitle>{type.name}</TypeTitle>
                          <ToggleWrapper>
                            <ChildrenCount>
                              {type.children.length}
                            </ChildrenCount>
                            <ToggleContainer
                              ref={ref}
                              isOpen={type.opened}
                              onClick={handleTypeToggle(type)}
                            >
                              <viewTreeIcons.arrow />
                            </ToggleContainer>
                          </ToggleWrapper>
                        </TypeContainer>
                      </TypesWrapper>
                      {!!type.children.length && type.opened &&
                        <ObjectsWrapper>
                          {type.children.map(child => {
                            return (
                              <ObjectContainer key={child.id} onClick={handleObjectClick(child)}>
                                {child.objectIdentifier}
                              </ObjectContainer>
                            )})
                          }
                        </ObjectsWrapper>
                      }
                    </>
                  )
                })
              }
            </ContentContainer>
          </Container>
        }
      </Content>
    </Main>
  )
}

export default Ppr