import { takeLatest, select, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { USER_SETTINGS_ENDPOINT } from '@/constants/apiRoutes'
import {
  SELECT_THEME,
  SELECT_SCHEME,
  SELECT_FONTSIZE,
  UPDATE_APPLICATIONS_LIST,
  SET_GLOBAL_FILTER_VALUES,
  RESET_GLOBAL_FILTER_VALUES,
  SET_SELECTED_NODE,
  UNPIN_NODE,
  MENU_SIDEBAR_PINNED,
  SET_MAP_LAYER,
  SET_ALARMS_FILTER_VALUES,
  TOGGLE_RUSSIAN_BORDERS,
  SELECT_REFRESH_RATE
} from '@/store/actions/appSettings'
import {
  getAppSettingsState,
  getApplications,
  getPinnedNode,
  getGlobalFilters,
  getAlarmsFilters, getObjectsGroupsPassportizationFilters
} from '@/store/selectors/appSettings'
import { push } from 'connected-react-router'

import {
  successSetSettings,
  errorSetSettings,
} from '@/store/actions/user/settings/putSettings'
import createNotifications from '@/helpers/notification'
import { SET_PASSPORTIZATION_TABLE_FILTER_VALUES } from '@/store/actions/objectsGroups'
import { getGoToPassportization } from '@/store/selectors/ppr'
import { PASPORTIZATION_URL } from '@/constants/routes'
import { setGoToPassportization } from '@/store/actions/Ppr'

function* putSettings() {
  try {
    const appSettings = yield select(getAppSettingsState)
    const applications = yield select(getApplications)
    const pinnedNode = yield select(getPinnedNode)
    const globalFilterValues = yield select(getGlobalFilters)
    const alarmsManagerFilterValues = yield select(getAlarmsFilters)
    const objectsGroupsPassportizationFilterValues = yield select(getObjectsGroupsPassportizationFilters)
    const goToPassportization = yield select(getGoToPassportization)
    delete pinnedNode.children

    const settingsForSave = {
      appSettings,
      generalPage: {
        applications,
      },
      pinnedNode,
      globalFilterValues,
      alarmsManagerFilterValues,
      objectsGroupsPassportizationFilterValues
    }

    const response = yield request({
      url: USER_SETTINGS_ENDPOINT,
      method: 'put',
      body: JSON.stringify(settingsForSave),
    })

    yield put(successSetSettings(response.data))
    if (goToPassportization) {
      yield put(push({
        pathname: PASPORTIZATION_URL,
      }))
      yield put(setGoToPassportization(false))
    }
  } catch (error) {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось сохранить настройки пользователя.\nПовторите попытку позже.',
      type: 'error',
    })
    yield put(errorSetSettings(error))
  }
}

export default function* root() {
  yield takeLatest(SELECT_THEME, putSettings)
  yield takeLatest(SELECT_SCHEME, putSettings)
  yield takeLatest(SELECT_FONTSIZE, putSettings)
  yield takeLatest(SELECT_REFRESH_RATE, putSettings)
  yield takeLatest(UPDATE_APPLICATIONS_LIST, putSettings)
  yield takeLatest(SET_GLOBAL_FILTER_VALUES, putSettings)
  yield takeLatest(RESET_GLOBAL_FILTER_VALUES, putSettings)
  yield takeLatest(SET_SELECTED_NODE, putSettings)
  yield takeLatest(UNPIN_NODE, putSettings)
  yield takeLatest(MENU_SIDEBAR_PINNED, putSettings)
  yield takeLatest(SET_MAP_LAYER, putSettings)
  yield takeLatest(TOGGLE_RUSSIAN_BORDERS, putSettings)
  yield takeLatest(SET_ALARMS_FILTER_VALUES, putSettings)
  yield takeLatest(SET_PASSPORTIZATION_TABLE_FILTER_VALUES, putSettings)
}
