import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import moment from "moment";
import noop from 'lodash/noop'
import { Formik } from 'formik'
import get from 'lodash/get'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import { DEFAULT_CELL_WIDTH } from '@/constants/sizes'
import { REQUEST_STATUSES } from '@/constants/requests'
import UsersIcons from '@/components/icons/users'
import DropDown from '@/components/blocks/DropDown'
import TextControl from '@/components/controls/TextControl'
import CheckBox from '@/components/controls/CheckBox'
import Loader from '@/components/blocks/Loader'
import isEdgeBrowser from '@/helpers/isEdgeBrowser'
import SelectControl from '@/components/controls/SelectControl'
import DatePickerField from '@/components/fields/DatePickerField'
import {
  DATE_FIRST,
  DATE_SECOND,
} from '@/constants/forms/analyticExpenses'
import SortBullet from './components/SortBullet'
import Table from './components/Table'
import TableFooter from './components/TableFooter'

import {
  FormContainer,
  FieldContainer,
  StylesWrapper,
  DecorativeElement,
  Header,
  CheckBoxContainer,
  HeaderContentWrapper,
  FilterContainer,
  SecondaryTitle,
  SearchButton,
  TableContainer,
  FooterContainer,
  CreateReportContainer,
  IconContainer,
  ResetSearchContainer,
  TooltipWrapper,
  ResetSearchWrapper
} from './styles'
import EmptyDataState from '@/components/blocks/EmptyDataState'
import PortalTooltip from '@/components/blocks/PortalTooltip'

const DutyTable = ({
  isNoData,
  fields,
  settingsOptions,
  exportOptions,
  isUsersTable,
  titlePpr,
  settingsYear,
  setYear,
  setStatus,
  settingsStatus,
  status,
  statusTitle,
  selectedNodeId,
  year,
  formatDate,
  setSelectedFieldsActive,  
  localSearch,
  scheduleActions,
  scheduleActionsOptions,
  setScheduleActions,
  liveTimeObjectTypesOptions,
  liveTimeObjectTypes,
  setLiveTimeObjectTypes,

  isAlarms,
  handleReadAllAlarms,
  activeFields,

  data,
  values,
  dates,
  actions,

  fileStatus,
  eventStatus,

  setStatusTypes,
  rowSelector,

  onUpdateData,
  onFileSelect,

  withFooter,
  withoutFooter,
  withHeader,
  withDates,
  customHeader,
  headerHeight,
  rowInfoCard: RowInfoCard,
  isInfoCardOpen,
  card,
  loadingCard,
  selectedRow,
  getTableData,
  buttonValue,
  showCheckBox,
  createReport,
  serviceDesk,
  wrapContent,
  scrollAfterUpdating,
  resetSearch,
  isDataLoading,
  viewOptions,
  handleViewChange,
  selectedView,
  scheduleManager,
  fieldOptions,
  handleFilterColumn,
  descriptions,
  showTimeSelect,
  formatWithTime,
  hideSelectFields,
  hideSortButtons,
}) => {
  const tableRef = useRef(null)

  useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.scroll(0, 0)
    }
  }, [scrollAfterUpdating, tableRef])

  const [isStatusSettingsOpen, setIsStatusSettingsOpen] = useState(false)
  const closeAllDropDown = () => {
    setIsStatusSettingsOpen(false)
  }
  const [isYearSettingsOpen, setIsYearSettingsOpen] = useState(false)
  const [isStatusPprSettingOpen, setIsStatusPprSettingOpen] = useState(false)

  const isFileLoading = fileStatus === REQUEST_STATUSES.PENDING
  const selected = useMemo(() => ({
    id: (values.sort || {}).field,
    value: (values.sort || {}).direction,
  }), [values])

  const mappedExportOptions = useMemo(() => exportOptions.map(({
    icon: Icon = React.Fragment,
    name,
    value,
  }) => ({
    title: (
      <>
        <Icon />
        {' '}
        {name}
      </>
    ),
    value,
  })), [exportOptions])
  const mappedFields = useMemo(() => fields.map((field) => ({
    ...field,
    title: field.name,
    value: field.id,
  })), [fields])
  const allColumns = useMemo(() => fields.map((field) => (field.id)), [fields])

  // const handleEventSelect = useCallback((value) => () => {
  //   if (eventTypes.includes(value)) {
  //     const newValues = eventTypes.filter((element) => element !== value)
  //     setEventTypes(newValues)
  //     return null
  //   }
  //   const newValues = [...eventTypes]
  //   newValues.push(value)
  //   setEventTypes(newValues)
  //
  //   return null
  // }, [eventTypes, setEventTypes])

  const handleSearchByTable = useCallback(() => {
    actions.setRequestQuery(values.query)
    if (localSearch) {
      getTableData(values.query)
    }
  }, [actions, values, localSearch, getTableData])

  const handleChangeSearchByTableValue = useCallback((value) => {
    if (!value) {
      actions.setRequestQuery(value)
      if (localSearch) {
        getTableData(value)
      }
    }
    actions.setQuery(value)
  }, [actions, localSearch, getTableData])

  const onSearchSubmit = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      handleSearchByTable()
    }
  }, [handleSearchByTable])

  const handleStatusSettingsSelect = useCallback((value) => () => {
    const isAllSelected = eventStatus.length === settingsOptions.length
    if (
      (value === 'all' && eventStatus.length === 0)
      || (value === 'new' && eventStatus.includes('old') && !isAllSelected)
      || (value === 'old' && eventStatus.includes('new') && !isAllSelected)
    ) {
      setStatusTypes(['all', 'new', 'old'])
      return null
    }
    if (value === 'all' && isAllSelected) {
      setStatusTypes([])
      return null
    }
    if (value === 'all' && eventStatus.length === 1) {
      setStatusTypes(['all', 'new', 'old'])
      return null
    }
    if ((value === 'new' || value === 'old') && eventStatus.length === 0) {
      setStatusTypes([value])
      return null
    }
    if (eventStatus.includes(value)) {
      const updatedStatuses = eventStatus
        .filter((status) => status !== value && status !== 'all')
      setStatusTypes(updatedStatuses)
      return null
    }
    return null
  }, [eventStatus, setStatusTypes, settingsOptions])

  const onSubmit = useCallback((values) => {
    getTableData({
      start: values[DATE_FIRST],
      end: values[DATE_SECOND],
    })
    if (setYear) {
      setYear(null)
    }
  }, [getTableData, setYear])

  const handleYear = (id) => {
    if (id === year) {
      setYear(null)
      getTableData({
        start: null,
        end: null,
      })
      return
    }
    setYear(id)
    getTableData({
      start: new Date(moment(id).format('DD.MM.YYYY')),
      end: id === '2024' ? null : new Date(moment(id).endOf('year').format('MM.DD.YYYY')),
    })
    setIsYearSettingsOpen(!isYearSettingsOpen)
  }

  const handleStatus = (id) => {
    if (id === status) {
      setStatus(null)
      return 
    }
    setStatus(id)
  }

  const handleFileSelect = useCallback((value) => {
    onFileSelect({value, allColumns, totalElements: values.resultCount})
  }, [onFileSelect, allColumns, values.resultCount])

  const columns = useMemo(
    () => fields.reduce((accumulator, element) => {
      if ((activeFields || values.activeFields || []).includes(element.id)) {
        return [
          ...accumulator,
          {
            Header: (
              <SortBullet
                {...element}
                showSortButtons={!hideSortButtons && data.length > 0}
                selected={selected}
                onClick={actions.setSort}
                showAlarmButton={isAlarms}
                handleReadAllAlarms={handleReadAllAlarms}
                buttonValue={buttonValue}
                showCheckBox={showCheckBox}
                isUsersTable={isUsersTable}
                withFilter={element.withFilter}
                fieldOptions={fieldOptions[element.id] || []}
                descriptions={descriptions || []}
                handleFilterColumn={handleFilterColumn}
              />
            ),
            accessor: element.id,
          },
        ]
      }
      return accumulator
    }, []),
    [
      fields,
      values.activeFields,
      data.length,
      selected,
      actions.setSort,
      isAlarms,
      handleReadAllAlarms,
      buttonValue,
      showCheckBox,
      isUsersTable,
      fieldOptions,
      descriptions,
      handleFilterColumn,
      activeFields,
      hideSortButtons
    ],
  )

  const toggleStatusSettings = () => {
    closeAllDropDown()
    setIsStatusSettingsOpen(!isStatusSettingsOpen)
  }

  const toggleYearSettings = () => {
    setIsYearSettingsOpen(!isYearSettingsOpen)
  }

  const toggleStatusPprSettings = () => {
    setIsStatusPprSettingOpen(!isStatusPprSettingOpen)
  }

  const handleSelectedFields = (value) => {
    if (setSelectedFieldsActive) {
      setSelectedFieldsActive(value)
    }
    actions.setSelectedFields(value)
  }

  const renderSettingsTypes = () => {
    if (!settingsOptions.length) {
      return null
    }
    return (
      <>
        {settingsOptions.map(({ name, id }) => (
          <CheckBoxContainer onClick={handleStatusSettingsSelect(id)}>
            <CheckBox
              value={eventStatus.includes(id)}
              childSelected={id === 'all'
                && !eventStatus.includes('all')
                && eventStatus.length > 0}
            />
            <SecondaryTitle>
              {name}
            </SecondaryTitle>
          </CheckBoxContainer>
        ))}
      </>
    )
  }
  const renderYears = () => {
    if (!settingsYear) {
      return null
    }
    return (
      <>
        {settingsYear.map(({ name, id }) => (
          <CheckBoxContainer onClick={() => handleYear(id)}>
            <CheckBox
              value={year === id}
              childSelected={id === 'all'
                && !eventStatus.includes('all')
                && eventStatus.length > 0}
            />
            <SecondaryTitle>
              {name}
            </SecondaryTitle>
          </CheckBoxContainer>
        ))}
      </>
    )
  }
  const renderStatus = () => {
    if (!settingsStatus) {
      return null
    }
    return (
      <>
        {settingsStatus.map(({ name, id }) => (
          <CheckBoxContainer onClick={() => handleStatus(id)}>
            <CheckBox
              value={status === id}
            />
            <SecondaryTitle>
              {name}
            </SecondaryTitle>
          </CheckBoxContainer>
        ))}
      </>
    )
  }

  const renderHeader = () => {
    const tableSettingsOption = renderSettingsTypes()
    const tableSettingsYear = renderYears()
    const tableSettingsStatus = renderStatus()
    return (
      <Header>
        <HeaderContentWrapper>
          {scheduleManager
              && (
                  <FilterContainer>
                    <SelectControl
                        autoWidth
                        value={selectedView}
                        options={viewOptions}
                        onChange={handleViewChange}
                    />
                  </FilterContainer>
              )
          }
          <FilterContainer>
            <Lang id="mapsPage.titles.tableSearch">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  value={values.query}
                  onChange={handleChangeSearchByTableValue}
                  onKeyDown={onSearchSubmit}
                />
              )}
            </Lang>
            <SearchButton onClick={handleSearchByTable}>
              <Lang id="search.buttons.accept" />
            </SearchButton>
          </FilterContainer>
          {createReport && (
            <CreateReportContainer onClick={createReport(true)}>
              <Lang id="reportManager.createReport" />
              <IconContainer>
                <UsersIcons.PlusIcon />
              </IconContainer>
            </CreateReportContainer>
          )}
          {!hideSelectFields && (
            <FilterContainer settingsOptions>
              <SelectControl
                autoWidth
                largeOptions
                multiselect
                placeholder={<Lang id="alarmManager.messages.setFields" />}
                multiPlaceholder
                options={mappedFields}
                value={activeFields || values.activeFields}
                onChange={handleSelectedFields}
              />
            </FilterContainer>
            )}
          {tableSettingsOption
            && (
              <FilterContainer>
                <DropDown
                  icon
                  content={tableSettingsOption}
                  title={(<Lang id="alarmManager.messages.setStats" />)}
                  isOpen={isStatusSettingsOpen}
                  onClick={toggleStatusSettings}
                />
              </FilterContainer>
            )}
          {scheduleActionsOptions && (
            <FilterContainer>
              <SelectControl
                autoWidth
                largeOptions
                multiselect
                placeholder={<Lang id="scheduleManager.titles.action" />}
                multiPlaceholder
                options={scheduleActionsOptions}
                value={scheduleActions}
                onChange={setScheduleActions}
              />
          </FilterContainer>
          )}
          {!!liveTimeObjectTypesOptions?.length && (
            <FilterContainer>
              <SelectControl
                autoWidth
                selectAll
                largeOptions
                multiselect
                placeholder={<Lang id="tableFields.serviceDeskMyCity.objectType" />}
                multiPlaceholder
                options={liveTimeObjectTypesOptions}
                value={liveTimeObjectTypes}
                onChange={setLiveTimeObjectTypes}
              />
          </FilterContainer>
          )}
          {tableSettingsStatus && (
            <FilterContainer>
              <DropDown
                icon
                content={tableSettingsStatus}
                title={statusTitle || <Lang id="alarmManager.messages.setStats" />}
                isOpen={isStatusPprSettingOpen}
                onClick={toggleStatusPprSettings}
              />
          </FilterContainer>
          )}
          {!!mappedExportOptions.length
              && (
                  <FilterContainer>
                    <SelectControl
                        autoWidth
                        largeOptions
                        placeholder={(
                            <>
                              <Lang id="alarmManager.messages.export" />
                            </>
                        )}
                        options={mappedExportOptions}
                        onChange={handleFileSelect}
                        disabled={isNoData && data.length === 0}
                    />
                    {isFileLoading ? (<Loader fill />) : null}
                  </FilterContainer>
              )
          }
          {setYear && (
              <FilterContainer>
                <DropDown
                  icon
                  content={tableSettingsYear}
                  title={year ? `${year} год` : <Lang id="datePicker.labels.year" />}
                  isOpen={isYearSettingsOpen}
                  onClick={toggleYearSettings}
                />
              </FilterContainer>
          )}
          {scheduleManager
              && (
                  <FilterContainer>
                    <SelectControl
                        autoWidth
                        value={'period'}
                        options={[{title: 'Период', value: 'period'}]}
                        onChange={()=>{}}
                    />
                  </FilterContainer>
              )
          }
          {scheduleManager
              && (
                  <FilterContainer>
                    <SelectControl
                        autoWidth
                        value={'interval'}
                        options={[{title: 'Интервал', value: 'interval'}]}
                        onChange={()=>{}}
                    />
                  </FilterContainer>
              )
          }
          {withDates && (
            <FilterContainer>
              <Formik
                initialValues={{
                  [DATE_FIRST]: dates.start,
                  [DATE_SECOND]: dates.end,
                }}
                enableReinitialize
                onSubmit={onSubmit}
                render={({ values: formValues, handleSubmit, dirty }) => {
                  if (!formValues[DATE_FIRST] && !formValues[DATE_SECOND] && dirty) {
                    onSubmit({
                      ...formValues
                    })
                  }
                  return (
                    <FormContainer>
                      <FieldContainer>
                        <DatePickerField
                          name={DATE_FIRST}
                          format={formatDate}
                          showTimeSelect={showTimeSelect}
                          formatWithTime={formatWithTime}
                        />
                        <DecorativeElement />
                        <DatePickerField
                          name={DATE_SECOND}
                          format={formatDate}
                          showTimeSelect={showTimeSelect}
                          formatWithTime={formatWithTime}
                        />
                      </FieldContainer>
                      {(formValues[DATE_FIRST] || formValues[DATE_SECOND]) && dirty && (
                        <SearchButton onClick={handleSubmit}>
                          <Lang id="scheduleManager.dateInterval.set" />
                        </SearchButton>
                      )}
                      {(formValues[DATE_FIRST] || formValues[DATE_SECOND]) && (
                        <SearchButton onClick={() => {
                          onSubmit({
                            ...formValues,
                            [DATE_FIRST]: null,
                            [DATE_SECOND]: null
                          })
                        }}>
                          <Lang id="scheduleManager.dateInterval.reset" />
                        </SearchButton>
                      )}
                    </FormContainer>
                  )
                }}
              />
            </FilterContainer>
          )}
          {resetSearch && (
              <ResetSearchWrapper>
                <TooltipWrapper>
                  <PortalTooltip
                      title={(<Lang id="tooltip.resetSearch" />)}
                      renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                          <ResetSearchContainer
                              ref={wrapperRef}
                              onMouseEnter={onMouseEnterHandler}
                              onMouseLeave={onMouseLeaveHandler}
                              onClick={resetSearch}
                          >
                            <Lang id="search.buttons.reset" />
                          </ResetSearchContainer>
                      )}
                  />
                </TooltipWrapper>
              </ResetSearchWrapper>
          )}
        </HeaderContentWrapper>
      </Header>
    )
  }

  const tableWidthByActiveFields = useMemo(() => get(values, 'activeFields', []).length * DEFAULT_CELL_WIDTH, [values])

  const renderTableFooter = useMemo(() => {
    if (withoutFooter) {
      return null
    }
    if (withFooter && data && data.length > 0 && RowInfoCard) {
      return (
        <FooterContainer>
          <TableFooter
            page={values.page}
            perPage={values.perPage}
            resultCount={values.resultCount}
            setPage={actions.setPage}
            setResultPerPage={actions.setPerPage}
            onUpdateData={onUpdateData}
            notToShowFooterPerPage
          />
          <RowInfoCard
            isInfoCardOpen={isInfoCardOpen}
            setIsInfoCardOpen={rowSelector}
            card={card}
            loading={loadingCard}
            selectedRow={selectedRow}
          />
        </FooterContainer>
      )
    }
    if (withFooter && data && data.length > 0) {
      return (
        <TableFooter
          page={values.page}
          perPage={values.perPage}
          resultCount={values.resultCount}
          setPage={actions.setPage}
          setResultPerPage={actions.setPerPage}
          onUpdateData={onUpdateData}
        />
      )
    }
    return null
  }, [
    RowInfoCard,
    actions.setPage,
    actions.setPerPage,
    data, onUpdateData,
    values.page,
    values.perPage,
    values.resultCount,
    withFooter,
    isInfoCardOpen,
    rowSelector,
    card,
    loadingCard,
    selectedRow,
    withoutFooter
  ])

  return (
    <TableContainer>
      {customHeader || (withHeader && renderHeader())}
      <StylesWrapper
        headerHeight={headerHeight}
        isEdge={isEdgeBrowser()}
        serviceDesk={serviceDesk}
        ref={tableRef}
      >
        {
            (!selectedView || selectedView === 'table') &&
            <Table
                columns={columns}
                data={data}
                perPage={values.perPage}
                fields={fields}
                rowSelector={rowSelector}
                withFooter={withFooter}
                minWidth={tableWidthByActiveFields}
                wrapContent={wrapContent}
                isDataLoading={isDataLoading}
                selectedRow={selectedRow}
            />
        }
        {
          selectedView === 'graph' &&
          <span>Graph</span>
        }
        {(!data.length && selectedView === 'table' && !isDataLoading) && (!(dates.start || dates.end) || !selectedNodeId ? titlePpr() : <EmptyDataState />)}
        {!data.length && !isDataLoading && <EmptyDataState /> }
        {/* {!data.length && selectedView === 'table' && !isDataLoading && (
          <EmptyDataState />
        )} */}
      </StylesWrapper>
      {renderTableFooter}
    </TableContainer>
  )
}

DutyTable.propTypes = {
  isNoData: pt.bool,
  fields: pt.arrayOf(pt.object),
  data: pt.arrayOf(pt.object),
  eventStatus: pt.arrayOf(pt.string),
  eventTypes: pt.arrayOf(pt.string),
  fileStatus: pt.string,
  onUpdateData: pt.func,
  onFileSelect: pt.func,
  rowSelector: pt.func,
  setStatusTypes: pt.func,
  setEventTypes: pt.func,
  exportOptions: pt.arrayOf(pt.shape({
    name: pt.string,
    icon: pt.element,
  }).isRequired),
  eventsOptions: pt.arrayOf(pt.object),
  settingsOptions: pt.arrayOf(pt.object),
  withFooter: pt.bool,
  withHeader: pt.bool,
  values: pt.shape({
    sort: pt.shape({
      field: pt.string,
      direction: pt.string,
    }),
    query: pt.string,
    requestQuery: pt.string,
    page: pt.number,
    perPage: pt.number,
    resultCount: pt.number,
    activeFields: pt.arrayOf(pt.string),
  }),
  actions: pt.shape({
    setSort: pt.func,
    setQuery: pt.func,
    setRequestQuery: pt.func,
    setSelectedFields: pt.func,
    setTotalResults: pt.func,
    setPerPage: pt.func,
    setPage: pt.func,
  }),
}

DutyTable.defaultProps = {
  isNoData: true,
  fields: [],
  data: [],
  eventStatus: [],
  eventTypes: [],
  fileStatus: REQUEST_STATUSES.NOT_REQUESTED,
  onUpdateData: noop,
  onFileSelect: noop,
  rowSelector: noop,
  setStatusTypes: noop,
  setEventTypes: noop,
  eventsOptions: [],
  settingsOptions: [],
  exportOptions: [],
  withFooter: true,
  withHeader: true,
  values: {},
  actions: {},
  fieldOptions: {},
}

export default DutyTable
