import React, {useCallback} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
// import DefaultImage from '@/components/blocks/DefaultImage'
import CkeckBox from '@/components/controls/CheckBox'
import { defaultImage, lightFixtureImage, prodDefaultImage, productionOnlyImage } from '@/assets/images/objectElement'

import {
  CARD,
  PASSPORT,
} from '@/constants/passportization'
import {
  TabContainer,
  PassportImageContainer,
  PassportView,
  TitleContainer,
  CkeckBoxContainer,
  OptionTitle,
  CkeckBoxsContainer,
} from './styles'
import {
  LIGHT_FIXTURE,
  LIGHT_FIXTURE_CONSOLE_LED,
  LIGHT_FIXTURE_CONSOLE_PARK_DNAT,
  LIGHT_FIXTURE_CONSOLE_ZKU,
  LIGHT_FIXTURE_FLOOR_LED,
  LIGHT_FIXTURE_FLOOR_ZKU, LIGHT_FIXTURE_GALEON_L,
  LIGHT_FIXTURE_GALEON_S,
  LIGHT_FIXTURE_SPOTLIGHT_LED,
  LIGHT_FIXTURE_SUSPENDED_LED,
  LIGHT_FIXTURE_SUSPENDED_ZKU,
  LIGHT_FIXTURE_WALL_LED,
  LIGHT_FIXTURE_WALL_ZKU,
  UTILITY_POLE,
  UTILITY_POLE_CONCRETE,
  UTILITY_POLE_METAL,
  UTILITY_POLE_WOOD
} from '@/constants/instalationPassport/types'

const PassportImage = ({
  element,
  uploadedImage,
  photoUrl,
  setTabType,
  tabType,
  selectedNode,
  create,
}) => {
  const getImageUrlProductionOnly = useCallback(() => { // SLS-1911
    if (selectedNode.installationType === UTILITY_POLE) {
      if (selectedNode.objectFields.filter(item => item.fieldName === 'UTILITY_POLE_TYPE')[0]?.value.includes('бетонная')) {
        return productionOnlyImage[UTILITY_POLE_CONCRETE]
      }

      if (selectedNode.objectFields.filter(item => item.fieldName === 'UTILITY_POLE_TYPE')[0]?.value.includes('металлическая')) {
        return productionOnlyImage[UTILITY_POLE_METAL]
      }

      return productionOnlyImage[UTILITY_POLE_WOOD]
    }

    if (selectedNode.installationType === LIGHT_FIXTURE) {
      switch (selectedNode.objectFields.filter(item => item.fieldName === 'LAMP_TYPE')[0]?.value) {
        case 'Консольный парковый ДНаТ': return productionOnlyImage[LIGHT_FIXTURE_CONSOLE_PARK_DNAT]
        case 'Консольный парковый светодиодный':
        case 'Консольный светодиодный': return productionOnlyImage[LIGHT_FIXTURE_CONSOLE_LED]
        case 'Настенный / потолочный ЖКУ': return productionOnlyImage[LIGHT_FIXTURE_WALL_ZKU]
        case 'Настенный / потолочный светодиодный': return productionOnlyImage[LIGHT_FIXTURE_WALL_LED]
        case 'Подвесной деко ЖКУ': return productionOnlyImage[LIGHT_FIXTURE_SUSPENDED_ZKU]
        case 'Подвесной деко светодиодный': return productionOnlyImage[LIGHT_FIXTURE_SUSPENDED_LED]
        case 'Прожектор светодиодный': return productionOnlyImage[LIGHT_FIXTURE_SPOTLIGHT_LED]
        case 'Торшерный деко ЖКУ':
        case 'Торшерный ЖКУ': return productionOnlyImage[LIGHT_FIXTURE_FLOOR_ZKU]
        case 'Торшерный деко светодиодный': return productionOnlyImage[LIGHT_FIXTURE_FLOOR_LED]

        default: return productionOnlyImage[LIGHT_FIXTURE_CONSOLE_ZKU]
      }
    }

    return prodDefaultImage[selectedNode.installationType]
  }, [selectedNode])

  const getImageUrl = useCallback(() => {
    if (!!selectedNode.url) {
      return selectedNode.url
    }

    if (!!photoUrl) {
      return photoUrl
    }

    if (selectedNode.path[3] === 50061) {      // change integration id before merge in prod SLS-1911
      return getImageUrlProductionOnly()
    }

    // temporary added, remove after Demo SLS-1794
    if (selectedNode.installationType === LIGHT_FIXTURE) {
      if (selectedNode.objectFields.filter(item => item.fieldName === 'MODEL_MARK')[0].value.includes('Галеон S')) {
        return lightFixtureImage[LIGHT_FIXTURE_GALEON_S]
      }

      if (selectedNode.objectFields.filter(item => item.fieldName === 'MODEL_MARK')[0].value.includes('Галеон L')) {
        return lightFixtureImage[LIGHT_FIXTURE_GALEON_L]
      }

      return lightFixtureImage[LIGHT_FIXTURE]
    }

    return defaultImage[selectedNode.installationType]
  }, [selectedNode, photoUrl, getImageUrlProductionOnly])

  return (
    <>
      <TabContainer>
        <TitleContainer>
          <Lang id="passportization.passportSidebar.view" />
        </TitleContainer>
        <CkeckBoxsContainer>
          <CkeckBoxContainer onClick={setTabType(PASSPORT)}>
            <CkeckBox
              value={tabType === PASSPORT}
            />
            <OptionTitle>
              <Lang id="passportization.passportSidebar.passport" />
            </OptionTitle>
          </CkeckBoxContainer>
          <CkeckBoxContainer onClick={setTabType(CARD)}>
            <CkeckBox
              value={tabType === CARD}
            />
            <OptionTitle>
              <Lang id="passportization.passportSidebar.card" />
            </OptionTitle>
          </CkeckBoxContainer>
        </CkeckBoxsContainer>
      </TabContainer>
      <PassportImageContainer>
      <PassportView url={!create ? getImageUrl() : null} base64Url={uploadedImage} />
        {/* {element.url || uploadedImage || photoUrl
          ? (<PassportView url={element.url || photoUrl} base64Url={uploadedImage} />)
          : (<DefaultImage title={(<Lang id="passportization.passportSidebar.noImage" />)} />)} */}
      </PassportImageContainer>
    </>
  )
}

PassportImage.defaultProps = {
  element: {},
  uploadedImage: null,
  photoUrl: '',
  setTabType: noop,
  tabType: PASSPORT,
}

PassportImage.propTypes = {
  element: pt.shape({
    url: pt.string,
  }),
  uploadedImage: pt.object,
  photoUrl: pt.string,
  setTabType: pt.func,
  tabType: pt.oneOf([CARD, PASSPORT]),
}

export default React.memo(PassportImage)
