import React, {
  useCallback, useMemo, useEffect, useState,
} from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import HeaderIcons from '@/components/icons/header'
import CoreIcons from '@/components/icons/core'
import HelpDropdown from '@/components/blocks/HelpDropdown'
import {
  DASHBOARD_URL,
  ALARMS_URL,
  SEARCH_URL,
} from '@/constants/routes'
import {
  routerHistoryType,
  routerHistoryDefault,
} from '@/propTypes/router'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'
import { isUserHasPermission } from '@/helpers/userPermissions'
import GlobalFilterForm from '@/forms/GlobalFilterForm'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import {
  INTEGRATION,
  ASU,
  OBJECT_TYPES,
  PROCESSES,
  OWNERS,
  EXPLOITATION_ORGANIZATIONS,
} from '@/constants/forms/globalFilterForm'
import {
  Container,
  ContentWrapper,
  ControlsContainer,
} from './styles'
import ControlsBlock from './ControlsBlock'

const AppHeader = ({
  history: { push },
  onSetGlobalFilterValues,
  onResetGlobalFilterValues,
  isProfileOpen,
  isSettingsOpen,
  syncInProgress,
  eventsCount,
  onToggleProfile,
  onToggleSettings,
  onGetAlarmsCount,
  onSetSelectedNode,
  onSetUnpinNode,
  eventsCountNotification,
  endGetAlarmsCountNotification,
  user,
  selectedNode,
  pinnedNode,
  globalFiltersValues,
  setStatusTypes,
  requestGetSearchFieldsOptions,
  searchFieldsOptions,
  requestGetAllConnectedIntegrations,
  connectedIntegrations,
  requestApplicationsVersion,
  applicationsFiles,
  requestAllApplicationsFiles,
  getSettingsPassport,
}) => {
  useEffect(() => {
    // requestGetSearchFieldsOptions()
    requestApplicationsVersion()
    requestAllApplicationsFiles()
    getSettingsPassport()
  }, [requestApplicationsVersion, requestAllApplicationsFiles, getSettingsPassport])
  
  useEffect(() => {
    if (isUserHasPermission(user, ID_BY_APPLICATIONS.ALARM_ANALYTICS)) onGetAlarmsCount()
  }, [onGetAlarmsCount,user])

  const disabledSlsLogo = process.env.REACT_APP_DISABLE_SLS_LOGO === 'true'
  const { href } = window.location

  const [isGlobalFilterOpen, setIsGlobalFilterOpen] = useState(false)
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  const toggleGlobalFilterPopup = useCallback(() => {
    setIsGlobalFilterOpen(!isGlobalFilterOpen)
  }, [isGlobalFilterOpen])

  const toggleHelpPopup = useCallback(() => {
    setIsHelpOpen(!isHelpOpen)
  }, [isHelpOpen])

  const onClearSelectedNode = useCallback(() => {
    if (pinnedNode && pinnedNode.id) {
      onSetUnpinNode({})
    }
  }, [pinnedNode, onSetUnpinNode])

  const onSelectedNode = useCallback(() => {
    if (selectedNode && selectedNode.id) {
      onSetSelectedNode(selectedNode)
    }
  }, [selectedNode, onSetSelectedNode])

  const toggleProfile = useCallback(
    () => onToggleProfile(!isProfileOpen),
    [onToggleProfile, isProfileOpen],
  )
  const toggleSettings = useCallback(
    () => onToggleSettings(!isSettingsOpen),
    [onToggleSettings, isSettingsOpen],
  )
  const openNotificationsPage = useCallback(
    () => {
      setStatusTypes(['new'])
      push(ALARMS_URL)
    },
    [push, setStatusTypes],
  )
  const handlePinIconClick = useCallback(() => {
    if (pinnedNode && pinnedNode.id) {
      return onClearSelectedNode()
    }
    if (selectedNode && selectedNode.id) {
      return onSelectedNode()
    }
    return noop
  },
  [
    selectedNode,
    pinnedNode,
    onClearSelectedNode,
    onSelectedNode,
  ])

  const handleSaveFilters = useCallback((values) => {
    onSetGlobalFilterValues(values)
    toggleGlobalFilterPopup()
  },
  [
    onSetGlobalFilterValues,
    toggleGlobalFilterPopup,
  ])

  const handleSearchIconClick = useCallback(
    () => {
      push(SEARCH_URL)
  },[push])

  const isGlobalFilterActive = useMemo(() => {
    const isHasIntegration = !!globalFiltersValues[INTEGRATION]
    const isHasASU = !!globalFiltersValues[ASU]
    const isHasType = !!globalFiltersValues[OBJECT_TYPES]
    const isHasProcess = !!globalFiltersValues[PROCESSES]
    const isHasOwner = !!globalFiltersValues[OWNERS]
    const isHasOrganization = !!globalFiltersValues[EXPLOITATION_ORGANIZATIONS]

    const valuesSelected = isHasIntegration || isHasASU || isHasType || isHasProcess || isHasOwner || isHasOrganization
    const isTypeDisabled = !globalFiltersValues[OK] || !globalFiltersValues[WARNING]
      || !globalFiltersValues[ERROR] || !globalFiltersValues[INFO]

    return valuesSelected || isTypeDisabled
  }, [globalFiltersValues])

  const renderSelectedTooltip = useCallback(() => {
    if (pinnedNode && pinnedNode.id) {
      return (
        <>
          <Lang id="menu.unpinNode" />:
          <br />
          {pinnedNode.name}
        </>
      )
    }
    if (selectedNode && selectedNode.id) {
      return (
        <>
          <Lang id="menu.pinNode" />:
          <br />
          {selectedNode.name}
        </>
      )
    }
    return (<Lang id="menu.emptyPinNode" />)
  }, [selectedNode, pinnedNode])

  const filters = useMemo(
    () => ([
      {
        icon: href.includes('search') ? <HeaderIcons.ActiveSearchIcon /> : <HeaderIcons.SearchIcon />,
        name: 'HeaderIcons.SearchIcon',
        title: (<Lang id="tooltip.search" />),
        onClick: handleSearchIconClick
      },
      {
        icon: pinnedNode && pinnedNode.id
          ? <HeaderIcons.SelectedPinIcon />
          : <HeaderIcons.NoSelectedPinIcon />,
        name: 'HeaderIcons.SelectedPinIcon',
        title: renderSelectedTooltip(),
        onClick: handlePinIconClick,
      },
      {
        icon: isGlobalFilterActive
          ? <HeaderIcons.ActiveGlobalFilterIcon />
          : <HeaderIcons.GlobalFilterIcon />,
        onClick: toggleGlobalFilterPopup,
        name: 'HeaderIcons.GlobalFilterIcon',
        title: (<Lang id="tooltip.filter" />),
        popupContent: (
          <GlobalFilterForm
            onSetValues={handleSaveFilters}
            onResetValues={onResetGlobalFilterValues}
            globalFiltersValues={globalFiltersValues}
            requestGetSearchFieldsOptions={requestGetSearchFieldsOptions}
            searchFieldsOptions={searchFieldsOptions}
            requestGetAllConnectedIntegrations={requestGetAllConnectedIntegrations}
            connectedIntegrations={connectedIntegrations}
          />
        ),
        opened: isGlobalFilterOpen,
      },
    ]),
    [
      pinnedNode,
      handlePinIconClick,
      renderSelectedTooltip,
      toggleGlobalFilterPopup,
      handleSaveFilters,
      onResetGlobalFilterValues,
      isGlobalFilterOpen,
      isGlobalFilterActive,
      globalFiltersValues,
      href,
      handleSearchIconClick,
      requestGetSearchFieldsOptions,
      searchFieldsOptions,
      requestGetAllConnectedIntegrations,
      connectedIntegrations
    ],
  )
  const controls = useMemo(
    () => ([
      {
        icon: <HeaderIcons.HelpIcon />,
        name: 'HeaderIcons.HelpIcon',
        title: (<Lang id="tooltip.help" />),
        onClick: toggleHelpPopup,
        popupContent: (
         <HelpDropdown data={applicationsFiles}/>
        ),
        opened: isHelpOpen,
      },
      {
        noAccess: !isUserHasPermission(user, [ID_BY_APPLICATIONS.ALARM_ANALYTICS, ID_BY_APPLICATIONS.ALARM_LOG]),
        icon: <HeaderIcons.NotificationsIcon />,
        name: 'HeaderIcons.NotificationsIcon',
        count: eventsCount,
        onClick: openNotificationsPage,
        title: (<Lang id="tooltip.notification" />),
      },
      {
        icon: <HeaderIcons.SettingsIcon className="notCloseSidebar" />,
        name: 'HeaderIcons.SettingsIcon',
        onClick: toggleSettings,
        syncInProgress,
        title: (<Lang id="tooltip.settings" />),
      },
      {
        icon: require('@/assets/images/user.png'),
        name: 'user-image',
        isImage: true,
        onClick: toggleProfile,
        className: 'notCloseSidebar',
        title: (<Lang id="tooltip.signOut" />),
      },
    ]),
    [
      toggleSettings,
      toggleProfile,
      syncInProgress,
      eventsCount,
      openNotificationsPage,
      user,
      isHelpOpen,
      applicationsFiles,
      toggleHelpPopup,
    ],
  )
  return (
    <Container>
      <ContentWrapper>
        <Link to={DASHBOARD_URL}>
          {!disabledSlsLogo && (<CoreIcons.LogoIcon />)}
          <CoreIcons.BLGLogoIcon />
        </Link>
        <ControlsContainer>
          <ControlsBlock
            controls={filters}
            eventsCountNotification={eventsCountNotification}
            endGetAlarmsCountNotification={endGetAlarmsCountNotification}
            withDelimiter
            spaced
          />
          <ControlsBlock
            controls={controls}
            eventsCountNotification={eventsCountNotification}
            endGetAlarmsCountNotification={endGetAlarmsCountNotification}
          />
        </ControlsContainer>
      </ContentWrapper>
    </Container>
  )
}

AppHeader.defaultProps = {
  syncInProgress: false,
  eventsCount: 0,
  history: routerHistoryDefault,
  eventsCountNotification: false,
}

AppHeader.propTypes = {
  history: routerHistoryType,
  onToggleProfile: pt.func.isRequired,
  onToggleSettings: pt.func.isRequired,
  onGetAlarmsCount: pt.func.isRequired,
  endGetAlarmsCountNotification: pt.func.isRequired,
  isProfileOpen: pt.bool.isRequired,
  isSettingsOpen: pt.bool.isRequired,
  syncInProgress: pt.bool,
  eventsCount: pt.number,
  eventsCountNotification: pt.bool,
  user: pt.shape({
    modules: pt.arrayOf(pt.number),
  }).isRequired,
}

export default withRouter(memoWithName(AppHeader))
