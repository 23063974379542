import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.NAME" />,
    showInDashboard: true,
    selector: 'NAME',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.IDENTIFIER" />,
    showInDashboard: true,
    selector: 'IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.EQUIPMENT_TYPE" />,
    showInDashboard: true,
    selector: 'EQUIPMENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.DEVICE_SERIAL_NUMBER" />,
    showInDashboard: true,
    selector: 'DEVICE_SERIAL_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.METER_SN" />,
    showInDashboard: true,
    selector: 'METER_SN',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
    showInDashboard: true,
    selector: 'MODEL_MARK',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
    showInDashboard: true,
    selector: 'PROCEDURE_SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.CONN_TYPE" />,
    showInDashboard: true,
    selector: 'CONN_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SWVER" />,
    showInDashboard: true,
    selector: 'SWVER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID" />,
    showInDashboard: true,
    selector: 'ICCID',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID1" />,
    showInDashboard: true,
    selector: 'ICCID1',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID2" />,
    showInDashboard: true,
    selector: 'ICCID2',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.TIME_ZONE" />,
    showInDashboard: true,
    selector: 'TIME_ZONE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS" />,
    showInDashboard: true,
    selector: 'NUMBER_MEASUREMENT_CHANNELS',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM" />,
    showInDashboard: true,
    selector: 'NUMBER_CONTROLLED_HEATING_SYSTEM',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW" />,
    showInDashboard: true,
    selector: 'MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE" />,
    showInDashboard: true,
    selector: 'TEMPERATURE_MEASUREMENT_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN" />,
    showInDashboard: true,
    selector: 'MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE" />,
    showInDashboard: true,
    selector: 'PRESSURE_MEASUREMENT_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE" />,
    showInDashboard: true,
    selector: 'DC_POWER_SUPPLY_VOLTAGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT" />,
    showInDashboard: true,
    selector: 'LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'END_DATE',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
    showInDashboard: true,
    selector: 'ORGANIZATION',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
    showInDashboard: true,
    selector: 'OWNER',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
    showInDashboard: true,
    selector: 'GUARANTEE_PERIOD',
    isCard: true
},
{
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
    showInDashboard: true,
    selector: 'LIFE_TIME',
    isCard: true
},


{
  passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
  showInDashboard: true,
  selector: 'PROJECT',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.LOCATION.LATITUDE" />,
  showInDashboard: true,
  selector: 'LATITUDE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.LOCATION.LONGITUDE" />,
  showInDashboard: true,
  selector: 'LONGITUDE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
  showInDashboard: true,
  selector: 'REGION',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
  showInDashboard: true,
  selector: 'CITY',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
  showInDashboard: true,
  selector: 'STREET',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
  showInDashboard: true,
  selector: 'HOME_NUMBER',
  isCard: true
},


{
  passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
  showInDashboard: true,
  selector: 'PLAN_PPR_DATE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
  showInDashboard: true,
  selector: 'FACT_PPR_DATE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.SERVICING.CHECKING_DATE" />,
  showInDashboard: true,
  selector: 'CHECKING_DATE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
  showInDashboard: true,
  selector: 'BYPASS_DATE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
  showInDashboard: true,
  selector: 'MAINTENANCE_DATE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
  showInDashboard: true,
  selector: 'REPAIR_DATE',
  isCard: true
},
{
  passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
  showInDashboard: true,
  selector: 'GUARANTEE_EXPIRATION_DATE',
  isCard: true
},
]
